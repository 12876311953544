body{
  margin: 0%;
  padding: 0%;
  font-family: gillory;
}
@font-face {
  font-family: gillory;
  src: url("../fonts/Gilroy-Black.ttf");
}
i.fa.fa-angle-right {
  font-size: 25px;
  font-weight: 900;
  display: inline-block;
}
a{
  text-decoration: none;
}
section.intro {
  padding: 0px 220px;
}
h1.gem-c-heading.govuk-heading-l.govuk-\!-margin-bottom-6 {
  display: flex;
  margin-left: 16%;
}
button#download {
  background: #048104d9;
  color: white;
}
.file-upload-container {
    text-align: center;
  }
  
  .upload-heading {
    font-size: 36px;
    color: #007bff;
    margin-bottom: 30px;
  }
  
  .file-upload-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .file-input {
    border: 2px solid #007bff;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .upload-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #00703c;
  }
  .gem-c-layout-super-navigation-header__header-logo {
    background: #0b0c0c;
    border-top: 1px solid #0b0c0c;
    margin-top: -1px;
    position: relative;
    padding: 14px;
    
}
.gem-c-layout-super-navigation-header {
  border-bottom: 9px solid #064d8c;
}
.resume-fetch-container {
  max-width: 800px;
 
  padding: 0px 0px;
  display: flex;
}
.fetch-heading {
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Add some space below the heading */
}

.fetch-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.certificate-input {
  padding: 10px;
  width: 100%;
  max-width: 400px; /* Limit the input width */
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.fetch-button {
  padding: 10px 20px;
  background-color: #00703c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.fetch-form{
display: block;
}
.fetch-button:hover {
  background-color: #00703c;
}

.pdf-container {
  margin-top: 20px;
}

.pdf-container iframe {
  width: 36pc;
  height: 600px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
span.govuk-header__logotype-text {
  font-weight: 700;
  color: white;
}
span.govuk-header__logotype {
  color: white;
  margin-left: 18%;
}
span.govuk-header__logotype:hover {
  border-bottom: 3px solid white;
}

.get-started-intro {
  padding-left: 21px;
}
p {
  font-family: sans-serif;
  width: 90%;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
button.fetch-button {
  background-color: #005a30;
  color: white;
  /* font-weight: 700; */
  padding: 12px 14px;
  display: block;
  margin-top: 2%;
  cursor: pointer;
  font-size: 35px;
 
}
input.certificate {
  width: 35rem;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 5px 10px;
}
.gem-c-feedback__prompt.gem-c-feedback__js-show.js-prompt {
  padding: 40px 14pc;
  font-family: math;
}

.govuk-footer__meta {
  padding: 1px 20pc;
}
.blue {
  background: #1d70b8;
  padding: 5px 10px;
  width: 63%;
  position: relative;
  left: 19%;
}
.bluee {
  background: #1d70b8;
  padding: 5px;
}

footer.gem-c-layout-footer.govuk-footer.gem-c-layout-footer--border {
  background: #f3f2f1;
}
ul.govuk-footer__inline-list.govuk-\!-display-none-print {
  display: flex;
  gap: 27px;
  padding: 40px 20pc;
  list-style: none;
  background: #f3f2f1;
  text-decoration: underline blue 2px;
}
h2.gem-c-feedback__prompt-question {
  background: #f3f2f1;
  padding: 0px 80px 70px;
text-align: center;

}
button.yes {
  padding: 14px 10px;
  width: 100%;
}
button.no {
  padding: 14px 10px;
  width: 100%;
}

input.certificate-input {
  width: 35rem;
  margin-bottom: 10px;
  font-size: 16px;
  padding: 5px 10px;
}
.btnn {
  position: absolute;
  display: flex;
  gap: 10px;
  padding: 3px;
  margin-left: 48%;
  margin-top: -6%;
}
.gem-c-feedback__prompt-question-answer {
  background: #f3f2f1;
  position: relative;
}
.pdf-container {
  margin-top: 119px;
  position: relative;
  width: 46pc; 
  right: 73%;}

/* Mobile Responsive */
@media (max-width: 768px) {
  
    .btnn {
        position: absolute;
        display: flex;
        gap: 10px;
        padding: 3px;
        margin-left: 35%;
        margin-top: -18%;
    }
    button.fetch-button {
      background-color: #005a30;
      color: white;
      font-weight: 900;
      padding: 12px 14px;
      display: block;
      margin-top: 2%;
      cursor: pointer;
      font-size: 21px;
      border-radius: 0;
      box-shadow: 1px 2px transparent;
  }
.pdf-container iframe {
  width: 23pc;
  height: 600px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.pdf-container {
  margin-top: 119px;
  position: relative;
  width: 46pc;
  right: 400px;
}
p {
  font-size: 15px;
  width: 100%;
}
.govuk-footer__meta {
  display: flex;

  font-size: 10px;
}
ul.govuk-footer__inline-list.govuk-\!-display-none-print {
  font-size: 10px;
  display: contents;
}

  .govuk-footer__meta {
      display: contents;
      font-size: 10px;
  }
  h2#who-can-use-this-service {
    font-size: medium;
}

  section.intro {
      padding: 0px 40px;
  }
h1.gem-c-heading.govuk-heading-l.govuk-\!-margin-bottom-6 {
font-size: medium;
/* margin: 0 auto; */
display: flex;
margin-left: 8vw;
}
button.yes {
padding: 14px 10px;
width: 41%;
}
button.no {
padding: 14px 10px;
width: 41%;
}


input.certificate {
    width: 88vw;
    padding: 1%;
}
input.certificate-input {
  width: 110%;
  padding: 5px;
}
}

@media (max-width: 320px) {
  .btnn {
      position: absolute;
      display: flex;
      gap: 10px;
      padding: 3px;
      margin-left: 35%;
      margin-top: -24%;
  }
}